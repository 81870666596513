import { observer } from "mobx-react-lite"
import { FC, useEffect, useState } from "react"

import styles from "../styles/Header.module.scss"

interface HeaderProps {
  isMobile?: boolean
  onOpenMenu: any
  goToSection: any
  onBackCall: any
}

const Header: FC<HeaderProps> = ({
  onOpenMenu, goToSection, onBackCall, isMobile = false
}) => {

  const [headerToggled, setHeaderToggled] = useState(false);

  useEffect(() => {
    const onScroll = (event: any) => {
      console.log(event.target.scrollingElement.scrollTop);
      const scrollTop = event.target.scrollingElement.scrollTop;
      if (scrollTop >= 18) {
        setHeaderToggled(true);
      } else {
        setHeaderToggled(false);
      }
    }
    
    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    }
  }, []);

  return (
    <>
      <header className={styles.header + (headerToggled ? " " + styles.fixed : "")}>
        <div className="container">
          <div className="row">
            <div className="col-6 col-md-2" style={{
              alignItems: "center",
              display: "flex"
            }}>
              <div className={styles.logo}>
                <a href="/">
                  <img src="/assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
              <div className="col-6 col-md-5 centered" style={{display: !isMobile ? "flex" : "none"}}>
                <div className={styles.menu}>
                  <div onClick={() => goToSection("about")}>О Компании</div>
                  <div onClick={() => goToSection("weworkwith")}>Наши услуги</div>
                  <div onClick={() => goToSection("reviews")}>Отзывы</div>
                  <div onClick={() => goToSection("footer")}>Контакты</div>
                </div>
              </div>
              <div className="col-6 col-md-5" style={!isMobile ? {display: "flex", alignItems: "flex-end", flexDirection: "column"} : {display: "none"}}>
                <div className={styles.data}>
                  <div className={styles.data_place}>
                    <div className={styles.data_place__text}>
                      <div><a href="https://go.2gis.com/s8gyd" target="_blank">г. Красноярск, Алексеева 111/1</a></div>
                      <div><a href="https://go.2gis.com/ug1ud" target="_blank">г. Красноярск, Горького 53</a></div>
                    </div>
                  </div>
                  <div className={styles.data_phone}>
                    <div>
                      <a href="https://go.2gis.com/45apo" target="_blank">г. Красноярск, Мичурина 14Б/1</a>
                      <a href="tel:+79832840022">+7 983 284 00 22</a>
                      
                    </div>
                    <div onClick={() => onBackCall()}><i className="fas fa-phone"></i></div>
                  </div>
                </div>
              </div>
              
              <div className="col-6 centered" style={{display: isMobile ? "block" : "none"}}>
                <div className={styles.menu_open} onClick={() => onOpenMenu()}><i className="fas fa-bars"></i></div>
              </div>

          </div>
        </div>
      </header>
    </>
  )
}

export default observer(Header)