import {MenuProps} from "antd";

export const DEVICES_INCLUDED = [
  {label: "Смартфоны", value: "smartphones"},
  {label: "Гаджеты", value: "gadgets"},
  {label: "Ноутбуки", value: "notebooks"},
  {label: "Моноблоки и PC", value: "pcs"},
  {label: "Игровые приставки", value: "games"},
  {label: "Телевизоры", value: "tvs"},
  {label: "Роботы-пылесосы", value: "robots"},
  {label: "Велосипеды", value: "bikes"},
  {label: "Электросамокаты", value: "electrosamokaty"},
  {label: "Гироскутеры", value: "gyroscooters"},
  {label: "Планшеты", value: "tablet"},
  {label: "Фото и видео техника", value: "photovideo"},
  {label: "Кухонная техника", value: "kitchen"},
  {label: "Другие устройства", value: "other"},
];

export const DEVICES_ITS: MenuProps['items'] = [
  { key: 'smartphones', label: "Смартфоны" },
  { key: 'gadgets', label: "Гаджеты" },
  { key: 'notebooks', label: "Ноутбуки" },
  { key: 'pcs', label: "Моноблоки и PC" },
  { key: 'games', label: "Игровые приставки" },
  { key: 'tvs', label: "Телевизоры" },
  { key: 'robots', label: "Роботы-пылесосы" },
  { key: 'bikes', label: "Велосипеды" },
  { key: 'electrosamokaty', label: "Электросамокаты" },
  { key: 'gyroscooters', label: "Гироскутеры" },
  { key: 'tablet', label: "Планшеты" },
  { key: 'photovideo', label: "Фото и видео техника" },
  { key: 'kitchen', label: "Кухонная техника" },
  { key: 'other', label: "Другие устройства" }
]
