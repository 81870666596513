import React from "react";

const Home = React.lazy(() => import("../../pages/Home"));
const Success = React.lazy(() => import("../../pages/Success"));

export interface IRoute {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}

export enum RouteNames {
  HOME = '/',
  SUCCESS = "/success"
}

export const publicRoutes: IRoute[ ] = [
  { path: RouteNames.HOME, exact: true, component: <Home /> },
  { path: RouteNames.SUCCESS, exact: true, component: <Success /> }
]

export const twRoutes: IRoute[ ] = []
