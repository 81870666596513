import { observer } from "mobx-react-lite"
import { FC, useEffect, useState } from "react"
import {isNullOrUndefined} from "../core/utils/utils"

import styles from "../styles/MobileMenu.module.scss"

interface MobileMenuProps {
  isOpen: boolean
  isMobile?: boolean
  onMenuClosed: any
}

const MobileMenu: FC<MobileMenuProps> = ({
  isOpen = false, isMobile = false, onMenuClosed
}) => {

  const [isMenuShown, setIsMenuShown] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isMenuOpenned, setIsMenuOpenned] = useState(false);

  const goToSection = (selector: string) => {
    const section = document.querySelector(`#${selector}`);
    if (!isNullOrUndefined(section)) {
      section.scrollIntoView({
        behavior: 'smooth'
      });
    }

    if (isMenuVisible) {
      closeMenu();
    }
  }

  const openMenu = () => {
    setIsMenuShown(true);
    setTimeout(() => {
      setIsMenuVisible(true);
      setTimeout(() => {
        setIsMenuOpenned(true);
      }, 100);
    }, 100);
  }

  const closeMenu = () => {
    onMenuClosed();
    setIsMenuOpenned(false);
    setTimeout(() => {
      setIsMenuVisible(false);
      setTimeout(() => {
        setIsMenuShown(false);
      }, 400);
    }, 100);
  }

  useEffect(() => {
    if (isOpen) {
      openMenu();
    } else {
      closeMenu();
    }
  }, [isOpen]);
  
  return (
    <>
      <section className={styles.mobile_menu} style={{display: isMobile && isMenuShown ? "block" : "none", opacity: isMenuVisible ? "1" : "0"}}>
        <div className={styles.mobile_menu__wrap} style={{right: isMenuOpenned ? "0px" : "-70%"}}>
          <div className={styles.mobile_menu__back} onClick={() => closeMenu()}><i className="fas fa-times"></i></div>

          <div className={styles.mobile_menu__items}>
            <div onClick={() => goToSection("about")}>О Компании</div>
            <div onClick={() => goToSection("weworkwith")}>Наши услуги</div>
            <div onClick={() => goToSection("reviews")}>Отзывы</div>
            <div onClick={() => goToSection("footer")}>Контакты</div>
          </div>

          <div className={styles.mobile_menu__bottom}>
            <div className={styles.mobile_menu__bottom_item}>
              <div className={styles.data_place__icon}><i className="fas fa-location-dot"></i></div>
              <div className={styles.data_place__text}>
                <div><a href="https://go.2gis.com/s8gyd" target="_blank">г. Красноярск, Алексеева 111/1</a></div>
                <div><a href="tel:+79131826699" target="_blank">+7 913 182 66 99</a></div>
              </div>
            </div>
            <div className={styles.mobile_menu__bottom_item}>
              <div className={styles.data_place__icon}><i className="fas fa-location-dot"></i></div>
              <div className={styles.data_place__text}>
                <div><a href="https://go.2gis.com/ug1ud" target="_blank">г. Красноярск, Горького 53</a></div>
                <div><a href="tel:+79832840022" target="_blank">+7 983 284 00 22</a></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default observer(MobileMenu)