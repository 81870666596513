import { FC, useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import AppRouter from './components/AppRouter';

import './styles/App.scss';
import MobileMenu from './components/MobileMenu';
import Header from './components/Header';
import {isNullOrUndefined} from './core/utils/utils';
import Modal from './components/Modal';

import InputMask from "react-input-mask"
import {Context} from '.';
import useWindowDimensions from './core/hooks/useWindowDimensions';
import {DEVICES_INCLUDED, DEVICES_ITS} from './core/store/data';
import {Dropdown, MenuProps, message} from 'antd';
import {ApiService} from './core/api';
import {useNavigate} from 'react-router-dom';

const itemsPoints: MenuProps['items'] = [
  { key: 'main', label: "Алексеева 111/1" },
  { key: 'sub', label: "Горького 53" }
]

const App: FC = () => {

  const { store } = useContext(Context);

  const navigate = useNavigate();
  
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");

  const [devices, setDevices] = useState(DEVICES_INCLUDED);
  
  const { width, height } = useWindowDimensions();

  const openMenu = () => {
    store.setIsMenuOpenned(true);
  }

  const goToSection = (selector: string) => {
    const section = document.querySelector(`#${selector}`);
    if (!isNullOrUndefined(section)) {
      section.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  const sendForm = async () => {
    if (userName == "") {
      message.error("Заполните поле Имя!");
      return;
    }
    if (userPhone == "") {
      message.error("Заполните поле Телефон!");
      return;
    }

    store.setIsBackCallVisible(false);

    let selDev = devices.find(device => device.value == store.selectedDevice);
    let selDevName = "";
    if (selDev != undefined) {
      selDevName = selDev.label;
    }

    const res = await ApiService.sendForm({
      name: userName,
      phone: userPhone,
      device: `${selDevName}; ${getPointName(store.selectedPoint)}`,
      token: "----"
    });

    setUserName("");
    setUserPhone("");

    message.success("Запрос на обратный звонок успешно отправлен! Ожидайте звонка менеджера.");
    navigate("/success");
  }

  const getGadgetName = (val: string) => {
    const exst = devices.find((itm: any) => itm.value == val);
    return exst != undefined ? exst.label : "";
  }

  const getPointName = (val: string) => {
    if (val == "main") {
      return "Алексеева 111/1"
    } else {
      return "Горького 53";
    }
  }

  useEffect(() => {
    console.log(width);
    if (width <= 960) {
      store.setIsMobile(true);
    } else {
      store.setIsMobile(false);
    }
  }, [width, height]);

  useEffect(() => {
    if (width <= 960) {
      store.setIsMobile(true);
    } else {
      store.setIsMobile(false);
    }

    setTimeout(() => {
      store.setIsFaderShown(false);
      setTimeout(() => {
        store.setIsFaderVisible(false);
      }, 400);
    }, 1300);
  }, []);

  return (
    <>
      <div className="fader" style={{display: store.isFaderVisible ? "block" : "none", opacity: store.isFaderShown ? "1" : "0"}}></div>

      <MobileMenu 
        isOpen={store.isMenuOpenned} 
        isMobile={store.isMobile}
        onMenuClosed={() => store.setIsMenuOpenned(false)} />

      <Header
        isMobile={store.isMobile}
        onOpenMenu={() => openMenu()}
        goToSection={(selector: string) => goToSection(selector)}
        onBackCall={() => {
          store.setIsBackCallVisible(true);
        }} />
        
      <AppRouter />

      <Modal
        visible={store.isBackCallVisible}
        title="Обратный звонок"
        onClose={() => store.setIsBackCallVisible(false)}
        maxWidth={"420px"}
        content={<>
          <div className="modal_text">
            <p>Укажите Ваши имя и телефон.<br/>Наш менеджер свяжется с Вами в ближайшее время!</p>
          
            <div className="modal_form">
              <div>
                <input type="text" placeholder="Имя" value={userName} onChange={(event) => setUserName(event.target.value)} />
              </div>
              <div>
                <InputMask mask="+7 999 999 9999" placeholder="Телефон" value={userPhone} onChange={(event: any) => setUserPhone(event.target.value)} />
              </div>
              <div>
                <label>Выберите устройство</label>
                <Dropdown trigger={['click']} menu={{ items: DEVICES_ITS, style: {maxHeight: "300px", overflowY: "scroll"}, onClick: (item) => {store.setSelectedDevice(item.key)} }}>
                  <div className="dropd dark"><span>{getGadgetName(store.selectedDevice)}</span> <i className="fas fa-chevron-down"></i></div>
                </Dropdown>
              </div>
              <div>
                <label>Выберите адрес</label>
                <Dropdown trigger={['click']} menu={{ items: itemsPoints, style: {maxHeight: "300px", overflowY: "scroll"}, onClick: (item) => {store.setSelectedPoint(item.key)} }}>
                  <div className="dropd dark"><span>{getPointName(store.selectedPoint)}</span> <i className="fas fa-chevron-down"></i></div>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => sendForm()}>Получить консультацию</button>
            </div>
          </div>
        </>} />
    </>
  )
}

export default observer(App)
