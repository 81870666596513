import { makeAutoObservable } from "mobx";
import { LOCALES } from "../i18n/locales";

export default class Store {

  isAuth = false;
  token: string = "";
  userData: any = {};
  locale: string = LOCALES.ENGLISH;

  isActiveA: boolean = false;
  portfolioRendered: boolean = false;

  isBackCallVisible: boolean = false;
  isMobile: boolean = false;
  isMenuOpenned: boolean = false;
  isFaderVisible: boolean = true;
  isFaderShown: boolean = true;

  selectedDevice: string = "smartphones";
  selectedDeviceName: string = "";
  selectedPoint: string = "main";

  constructor() {
    makeAutoObservable(this);
  }

  setIsBackCallVisible(bl: boolean) {
    this.isBackCallVisible = bl;
  }

  setIsMobile(bl: boolean) {
    this.isMobile = bl;
  }

  setSelectedDevice(bl: string) {
    this.selectedDevice = bl;
  }

  setSelectedPoint(bl: string) {
    this.selectedPoint = bl;
  }

  setSelectedDeviceName(bl: string) {
    this.selectedDeviceName = bl;
  }

  setIsMenuOpenned(bl: boolean) {
    this.isMenuOpenned = bl;
  }

  setIsFaderVisible(bl: boolean) {
    this.isFaderVisible = bl;
  }

  setIsFaderShown(bl: boolean) {
    this.isFaderShown = bl;
  }

}
