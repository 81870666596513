import axios from "axios";

export class ApiService {

  static async sendForm(dto: {
    name: string;
    phone: string;
    device: string;
    token: string;
  }) {
    return await axios.post("https://download.topgunadsnetwork.ru/core/jetmessage", dto);
  }

}